import React from "react";
import Carousel, { Dots, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import tw from "twin.macro";
import { SectionHeading as SectionHeadingBase, Subheading as SubheadingBase } from "components/misc/Headings.js";

/* LOGOS */
import imgCrowne from '../images/customers/crowne-plaza.png';
import imgDhighali from '../images/customers/dhigali.png';
import imgHJ from '../images/customers/howard.png';
import imgKW from '../images/customers/kw.png';
import imgOrchid from '../images/customers/orchid.png';

export function AtomCarousel(props) {
    const images = [
        imgCrowne,
        imgDhighali,
        imgHJ,
        imgKW,
        imgOrchid
    ]
    const SubHeading = tw(SubheadingBase)`text-center md:text-center`;
    const SectionHeading = tw(SectionHeadingBase)`text-center text-2xl sm:text-3xl lg:text-3xl text-center`;
    return (
        <div style={{ width:'86%', padding: '80px 20px 40px', margin: '0 auto', maxWidth:1700 }}>
            {
            (props.withHeading) &&
            <div style={{padding:'10px 0 45px'}}>
            <SubHeading>Our Partners</SubHeading>
            <SectionHeading>Trusted by 20+ star hotel chains worldwide</SectionHeading>
            </div>
            }
            <div style={{opacity:0.75}}>
                <Carousel
                    plugins={[
                        'infinite',
                        'arrows',
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 5
                            }
                        },
                    ]}
                >
                    {images.map( (img, i) => <img src={img} key={i}/>)}
                </Carousel>
            </div>
        </div>
    )
}
