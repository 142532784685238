import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import ThreeColSimple from "./components/features/ThreeColSimple";
import TwoColumnWithImageAndProfilePictureReview from "./components/testimonials/TwoColumnWithImageAndProfilePictureReview";
import PopularAndRecentBlogPosts from "./components/blogs/PopularAndRecentBlogPosts";
import SimpleWithSideImage from "./components/faqs/SimpleWithSideImage";
import GetStartedLight from "./components/cta/GetStartedLight";
import SimpleFiveColumn from "./components/footers/SimpleFiveColumn";
import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";
import ThreeColWithSideImageWithPrimaryBackground from "./components/features/ThreeColWithSideImageWithPrimaryBackground";
import ThreeColCenteredStatsPrimaryBackground from "./components/features/ThreeColCenteredStatsPrimaryBackground";
import AnimationRevealPage from "./helpers/AnimationRevealPage";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import { AtomCarousel } from "./atom/AtomCarousel";
import { PlatformsSupported } from "./atom/PlatformsSupported";



const Atom = () => {
    return (
        <>
        <AnimationRevealPage disabled={true}>
            <Hero></Hero>
            <AtomCarousel withHeading></AtomCarousel>
            <ThreeColWithSideImageWithPrimaryBackground/>
            <PlatformsSupported></PlatformsSupported>
            {/*<ThreeColSimple></ThreeColSimple>*/}
            <TwoColumnWithImageAndProfilePictureReview/>
            <AtomCarousel></AtomCarousel>
            <WithStatsAndImageFeatures/>
            <PopularAndRecentBlogPosts/>
            <ThreeColCenteredStatsPrimaryBackground/>
            <Portfolio></Portfolio>
            <SimpleWithSideImage/>
            <ContactUsForm/>
            <GetStartedLight/>
            <SimpleFiveColumn/>
            </AnimationRevealPage>
        </>
    )
}
export default Atom;
