import React from "react";

import imagePlatform from './../assets/images/platforms_supported.png';

export function PlatformsSupported() {
    return (
        <div style={{margin:'0 auto', width: '100%'}}>
            <img src={imagePlatform} alt="" style={{margin:'0 auto', maxWidth: '90%', filter: 'grayscale(1)'}}/>
        </div>

    )
}
